import { useState } from "react";
import "./App.css";

function App() {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState("");

  const onPromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!prompt) return;

    setAnswer("");
    setLoading(true);

    try {
      const response = await fetch("https://ai.jackfrankland.com/ask", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      });
      const { answer } = await response.json();
      setAnswer(answer);
      setLoading(false);
    } catch (e) {
      setAnswer("error");
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header>
        <h1 dangerouslySetInnerHTML={{ __html: "{'>'}" }}></h1>
      </header>
      <main>
        <form onSubmit={onSubmit}>
          <input name="prompt" value={prompt} onChange={onPromptChange}></input>
          <button type="submit" disabled={loading}>
            Submit
          </button>
        </form>
        <textarea
          readonly
          value={answer}
          disabled={loading || !answer}
        ></textarea>
      </main>
    </div>
  );
}

export default App;
